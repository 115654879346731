.capture-container {
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: rgba(255, 255, 255, .7);
}

.capture-container > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.capture-webcam a,
.capture-container a {
    color: #0881c7 !important;
    cursor: pointer;
}

.capture-webcam {
    width: 285px;
    height: 226px;
    display: grid;
    overflow: hidden;
    position: relative;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;

    --border-angle: 0turn;
    --main-bg: conic-gradient(from var(--border-angle),
            #0881c7,
            #0881c7 5%,
            #0881c7 60%,
            #0881c7 95%);

    border: solid 5px transparent;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, rgb(255, 255, 255) 99%, transparent);

    background:
        var(--main-bg) padding-box,
        var(--gradient-border) border-box,
        var(--main-bg) border-box;

    background-position: center center;
    animation: bg-spin 3s linear infinite;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;

}

.capture-webcam video {
    position: absolute;
}

.loading-content {
    width: 410px;
    padding: 15px;
    background-color: #edf4f7;
    text-align: center;
}

.loading-content img {
    width: 370px;
    margin: auto;
    margin-bottom: 15px;
}