// Navbar

.navbar {
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;

  .dropdown-menu {
    right: 0;
    left: initial;
    background-color: #0868a0;
    width: 250px;
  }

  .btn-outline-light:not(:disabled):not(.disabled):active,
  .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle,
  .btn-outline-light:hover {
    color: $primary;
  }

  .dropdown-item{
    color: #FFFFFFB3;
    padding: 10px 20px;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    text-decoration: none;
    border-color: #fff;
    background-color: #086296;
    color: #fff;
    cursor: pointer;
    border-left: 3px solid white;
  }
  .dropdown-divider{
    width: 30px;
    margin: 20px;
    border-top: 3px solid #FFFFFFB3;
  }
  
  .dropdown{
    height: 100%;
  }

  

  .dropdown button{
    height: 100%;
    border-radius: 0;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .btn-outline-light:hover{
    color:#FFFFFFB3;
    background-color: #0868a0;
  }
  .show > .btn-outline-light.dropdown-toggle{
    background-color: #0868a0;
    color:#FFF;
    border: 0;
    box-shadow: none;
  }

  .dropdown-toggle::after{
    display: none !important;
  }
}

.navbar-brand {
  width: 190px;
  text-align: center;
}

.navbar-toggler {
  &:focus {
    outline: 0;
  }
}

.navbar-toggler-icon {
  width: 1rem;
}

.sidebar{
  margin-top: 10px;
}

.sidebar .sidebarItem a, .sidebarItemComFilhos{
  padding: 15px 5px 15px 20px !important;
}

.sidebar .sidebarSubItem a{
  padding: 15px 5px 15px 30px !important;
}

/*.table{
  background-color: #FFF;
  
  thead th{
    vertical-align: inherit;
  }

  tr{
    height: 40px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}*/

.page-item{
  cursor: pointer;
}