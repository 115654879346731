#wizard-navigation {
  bottom: 0;
  right: 30px;
  left: 240px;
  z-index: 2;
}

.inner-scroll {
  max-height: calc(100vh - 253px);
  padding-left: 1px;
  overflow-y: scroll;
  overflow-x: hidden;
}
