.fc-toolbar.fc-header-toolbar {
  text-transform: capitalize;
}

.fc-slats,
.fc-time-grid,
.table-bordered,
.fc-view {
  z-index: 0 !important;
}

.abrev-nome {
  height: 75px;
  width: 75px;
}

.fc-bgevent {
  opacity: 1 !important;
}

.item-agendamento {
  min-height: 15px;
  padding-left: 5px;
  vertical-align: middle;
}

.fc-event-container {
  margin: 0 15% 0 2px !important;
}

.fc-day.disabled {
  background-color: #cecece;
}
