// Forms

.form-login {
  margin: auto;
  max-width: 300px;

  .form-control {
    border: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & + .form-control,
    & + .form-control:focus {
      border-top: 1px solid $gray-400;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.form-label {
  font-size: 0.875rem;
}

.form-group.required label:after {
  content: ' *';
  color: red;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  display: none !important;
}

.MuiFormControl-root {
  border: 1px solid #ced4da !important;
  height: 38px;
}

.MuiInputBase-input {
  padding: 9px 0.75rem !important;
  font-size: 13px;
}

.MuiIconButton-root {
  outline: 0 !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: $primary !important;
}

.checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
}

// Checkbox
.checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

/* Box */
.checkbox > span::before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px; /* Safari */
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.checkbox > span::after {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 5px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
}

.checkbox > input:checked {
  background-color: $primary;
}

.checkbox > input:checked + span::before {
  border-color: $primary;
  background-color: $primary;
}

.checkbox > input:checked + span::after {
  border-color: rgb(255, 255, 255);
  transform: rotate(-45deg);
}

.checkbox:hover > input {
  opacity: 0.04;
}

.checkbox:hover > input:focus {
  opacity: 0.16;
}

.checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.checkbox > input:active + span::before {
  border-color: $primary;
}

.checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(187, 45, 45, 0.6);
}
.checkbox.labeless > span::before {
  margin: 0px !important;
}
.checkbox.labeless > span::after {
  top: 6px !important;
  left: 4px !important;
}

.MuiTypography-body1,
.MuiSelect-select {
  font-size: 0.875rem !important;
}

// .Mui-checked .MuiSvgIcon-root {

// }

@media (min-width: 576px) {
  .modal-dialog {
    min-width: 40vw;
    max-width: 90vw;
  }
  .modal-agenda .modal-dialog {
    min-width: 60vw;
  }
}

@media (min-width: 992px) {
  .modal-agenda .modal-dialog {
    width: 800px;
    min-width: inherit;
  }
}


.modal-documentos {
  .modal-dialog {
    max-width: 45vw;
  }

  label.anexe {
    display: block;
    text-align: center;
    padding: 30px;
    cursor: pointer;
  }
}