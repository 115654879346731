// Tables

.table {
  table-layout: fixed;
  .d-flex{
    height: 55px;
  }
  th {
    color: #737373;
    
  }
  
  td {
    color: #333;
    vertical-align: middle;
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: 35px;

    .d-flex{
      height: 55px;
    }

    .toast{
      width: auto;
      display: -webkit-inline-box;
      margin-right: 3px;
      margin-left: 3px;
      margin-bottom: 4px;
      margin-top: 4px;

      .toast-header{
        padding-left: 0px;

        button{
          padding-top: 0px;
          padding-bottom: 0px;
        }

        .form-control{
          margin-left: 5px;
        }
      }
    }
  }
  
  tr.cursor-pointer:hover {
    background-color: #c8c8c8;
  }
  
}

@media (max-width: 1366px) {
  .table{
    td, th{
      font-size: 12px;
      padding: 3px;
    }
    .text-center.d-flex.justify-content-around{
      padding: 10px;
    }
  }
} 
