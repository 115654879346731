//Date Picker

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

#dataNascimento {
  font-size: 0.875rem;
}
