// Buttons
.label-chindren-link{
  .btn{
    height: 10px;
    margin-top: -22px;
  }
}

.button-icon {
  height: 40px;
  width: 40px;
  font-size: 18px;
  text-align: center;
  padding: 0;
}

.btn-login {
  transition: 0.3s;
  color: $primary;
  font-weight: $font-weight-bold;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1) !important;

  &:hover {
    color: $white;
    background-color: transparent;
  }

  &:active {
    transform: scale(0.85);
    color: $primary !important;
    background-color: $white !important;
  }

  &.disabled,
  &:disabled {
    opacity: 1;
    color: $primary;

    &:hover {
      background-color: $white;
    }
  }
}

.btn-delete-field {
  margin-top: 15px;
  margin-left: -33px;
  margin-right: 20px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-forget {
  color: $white !important;

  &:hover {
    color: $gray-400 !important;
  }
}

.btn-right {
  float: right;
}