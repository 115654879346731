// Sidebar

.sidebar {
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
  position: fixed;
  padding-top: 56px;
  background-color: #0868a0;
  transition: 0.3s;
  z-index: 2;
  overflow-y: scroll;

  .activeItemComFilhos {
    border-left: 3px solid $white;
    background-color: #086296;

    .sidebarItemComFilhos {
      border-left: 0px;

      span {
        color: $white;
        font-weight: bold;
      }
    }

    .sidebarSubItem {
      a {
        border-left: 0px;
      }
    }
  }

  .activeItem {
    background-color: #086296;

    span {
      color: $white;
      font-weight: bold;
    }
  }

  .sidebarItem {
    a {
      color: rgba(255, 255, 255, 0.7);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8.75px 5px 8.75px 20px;
      border-left: 3px solid transparent;

      &:hover,
      &.active {
        color: $white;
        text-decoration: none;
        border-color: $white;
        background-color: #086296;
      }
    }

    svg {
      width: 20px !important;
    }

    .fa {
      width: 20px !important;
    }
  }

  .sidebarItemComFilhos {
    color: rgba(255, 255, 255, 0.7);
    padding: 8.75px 5px 8.75px 20px;
    border-left: 3px solid transparent;

    &:hover,
    &.active {
      text-decoration: none;
      border-color: $white;
      background-color: #086296;
      color: $white;
      cursor: pointer;
    }

    svg {
      width: 20px !important;
    }

    .fa {
      width: 20px !important;
    }
  }

  .sidebarSubItem {
    a {
      color: rgba(255, 255, 255, 0.7);
      padding: 8.75px 5px 8.75px 35px;
      border-left: 3px solid transparent;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &:hover {
        text-decoration: none;
        border-color: $white;
        background-color: #086296;
        color: $white;
        cursor: pointer;
      }

      &.active {
        background-color: #086296;
        color: $white;
        font-weight: bold;
      }
    }

    svg {
      width: 20px !important;
    }

    .fa {
      width: 20px !important;
    }
  }
}
