// Custom variables
@import 'utils/variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Utils
@import 'utils/globals';

// Components
@import 'components/card';
@import 'components/buttons';
@import 'components/forms';
@import 'components/tables';
@import 'components/navbar';
@import 'components/sidebar';
@import 'sweetalert2/src/sweetalert2.scss';
@import 'components/datepicker';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import 'components/calendar';
@import 'components/stepwizard';
@import 'components/duallistbox';

@import '~react-daterange-picker/dist/css/react-calendar.css';

.DateRangePicker {
    width: 570px;
    position: fixed !important;
    display: flex !important;
    background-color: white;
    margin-top: 50px;
    z-index: 1;
}

.DateRangePicker__CalendarSelection--is-pending,
.DateRangePicker__CalendarSelection {
    background-color: var(--primary) !important;
}

.DateRangePicker__CalendarSelection {
    border: 1px solid var(--primary) !important;
}

.DateRangePicker__CalendarHighlight--single {
    border: 1px solid var(--primary) !important;
}

.card-dashboard {
    background-color: #FFFFFF;
    border-left: 3px solid #0768A1;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    .image {
        .content {
            background: #FAFAFA;
            min-width: 77px;
            min-height: 75px;
            border-radius: 100%;
            margin-left: -25px;
        }
    }

    .title {
        color: #0881C7;
        font-weight: bold;
        font-size: 12px;

        &.money {
            font-size: 20px;

            sub {
                bottom: -0.10em;
                font-weight: normal;
                font-size: 12px;
            }
        }
    }

    .common {
        font-size: 16px;
        color: #868686;
    }
}

.bg-shadow {
    background: #FCFCFD;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    border-radius: 15px;
}

.card-metric-dashboard {

    .icon {
        background: #FFFFFF;
        box-shadow: 0px 71.2527px 51.5055px rgba(229, 229, 245, 0.189815), 0px 42.3445px 28.0125px rgba(229, 229, 245, 0.151852), 0px 21.9866px 14.2913px rgba(229, 229, 245, 0.125), 0px 8.95749px 7.16599px rgba(229, 229, 245, 0.0981481), 0px 2.03579px 3.46085px rgba(229, 229, 245, 0.0601852);
        border-radius: 10px;
        padding: 5px;
        max-width: 43px;
        max-height: 43px;
        text-align: center;
    }

    background: #F2F3F7;
    font-size: 14px;
    border-radius: 5px;
    padding: 15px 5px;
    margin: 0px 15px;

    .value {
        font-weight: bold;
        font-size: 16px;
    }

    p {
        margin-bottom: 0px;
    }
}

.modal-schedule {
    max-width: 100vw;
}