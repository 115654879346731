// Variables

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

// Color system
$primary: #0881c7;

// Typography
$font-family-base: 'Roboto', sans-serif;

// Components
$border-radius: 3px !default;
$border-radius-lg: 3px !default;
$border-radius-sm: 3px !default;

// Typography
$font-size-base: .875rem !default;

// Buttons
$btn-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) !default;

// Forms
$input-focus-box-shadow: none !default;

// Navbar
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgb(255, 255, 255)' stroke-linecap='butt' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: transparent !default;