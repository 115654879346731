// Cards

.cardColaborador {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: rgba(2, 117, 216, 0.1);
  }

  h4,
  i {
    color: #0275d8;
  }
}

.listaCards {
  .row {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}


.card-datas {
  .card-header {
    background-color: transparent;
  }
}