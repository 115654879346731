// Globals

html,
body,
#root {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

.wrap {
  transition: 0.3s;
  padding-left: 80px;
  padding-top: calc(55px + 1.5rem);
}

.has-sidebar {
  padding-left: 235px;
}

.fix-btn-margin {
  margin-top: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.full-screen-container {
  top: 0;
  left: 0;
  z-index: 99999999;
  background-color: white;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
}

.bg-primary-03 {
  background-color: rgba($color: #0881c7, $alpha: 0.3) !important;
}

.bg-secondary-03 {
  background-color: rgba($color: #dee2e6, $alpha: 1) !important;
}

.bg-success-03 {
  background-color: rgba($color: #28a745, $alpha: 0.3) !important;
}

.bg-warning-03 {
  background-color: rgba($color: #ffc107, $alpha: 0.3) !important;
}

#rocket-chat-button {
  bottom: 30px;
  height: 60px;
  width: 60px;
  font-size: 2em;
}

#rocket-chat-window {
  z-index: 999;
  right: 20px;
  bottom: 10px;
  height: calc(100vh - 90px);
  width: 30%;
  max-width: 500px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.disabled-datepicker {
  background-color: #e9ecef;
}

#print {
  visibility: hidden;
}

@media print {
  body * {
      visibility: hidden;
  }
  #print, #print * {
      visibility: visible;
  }
  #print {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      .collapse {
        display: block;
      }
  }
}